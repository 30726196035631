import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import socials from '@/dictionaries/social';
export default {
  name: 'AddFacebook',
  computed: {
    facebook: function facebook() {
      return socials.filter(function (item) {
        return item.id === 'fb';
      })[0];
    }
  },
  methods: {
    close: function close() {
      this.$store.dispatch('modals/setAddFacebook', {
        isOpen: false
      });
    },
    openSocialWindow: function openSocialWindow(social) {
      var href = social.href,
          name = social.name;
      window.open(href, name, 'width=865,height=665');
    }
  }
};